import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import SidebarInner from '@shared/ui/sidebars/SidebarInner';
import IconWithText from '@shared/ui/display/IconWIthText';
import { StepContainer } from '@shared/ui/sidebars/shared';
import Button from '@shared/ui/buttons/Button';
import useBinanceConnect from './useBinanceConnect';
import { AlertIcon } from './shared';
const Error = () => {
    const { t } = useTranslation();
    const { goToNextStep } = useBinanceConnect();
    return (_jsx(SidebarInner, { children: _jsx(StepContainer, { content: (_jsx(IconWithText, { text: t('overview.connect_binance_exchange.error.title'), IconElement: AlertIcon() })), buttons: (_jsx(Button, { onClick: () => goToNextStep(), size: 'large', fullWidth: true, children: t('overview.connect_binance_exchange.success.continue_btn') })) }) }));
};
export default Error;
